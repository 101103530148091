$aos-distance: 2em;
@import '../../node_modules/aos/src/sass/aos.scss';

html {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    word-break: break-word;
    overflow-x: hidden;
}

body {
    overflow-x: hidden;
}

.box {
    border-radius: 4px !important;
}

.sky-gradient-1 {
    background: linear-gradient(to bottom, #020111 10%,#3a3a52 100%);
}

.is-size-1-custom {
    font-size: 3rem;
}

.is-size-2-custom {
    font-size: 2.5rem;
}

.is-size-3-custom {
    font-size: 2rem;
}

.is-size-4-custom {
    font-size: 1.5rem;
}

.is-size-5-custom {
    font-size: 1.25rem;
}

.is-size-6-custom {
    font-size: 1rem;
}

.is-size-7-custom {
    font-size: 0.75rem;
}

.navbar-item:focus:not(.router-link-active):not(:hover){
    background-color: unset !important;
    color: unset !important;
}

.navbar-burger:hover {
    background-color: rgba(0, 0, 0, 0) !important;
}
.navbar-center-menu {
    margin-left: auto;
    margin-right: auto;
}
.navbar-item-center {
    display: flex;
    justify-content: center !important;
}
.navbar-item-center-dropdown {
    flex-direction: column !important;
    text-align: center !important;
}
.navbar-dropdown-no-padding {
    padding-top: unset !important;
    padding-bottom: unset !important;
}
.navbar-dropdown.is-boxed {
    border-radius: 4px !important;
}
.navbar-dropdown a.navbar-item {
    border-radius: 4px !important;
}
.display-flex {
    display: flex;
}
.hero-absolute {
    position: absolute;
}
.hero-fixed {
    position: fixed;
}
.hero.has-background {
    overflow: hidden;
}
.is-transparent {
    opacity: .3;
}
.is-transparent-2 {
    opacity: .5;
}
.is-transparent-4 {
    opacity: .65;
}

.flex-end {
    align-self: flex-end;
}

.width-100p {
    width: 100%;
}
.height-100p {
    height: 100%;
}
.height-100vh {
    height: 100vh;
}
.height-40vh {
    height: 40vh;
}

.width-100-height-100 {
    width: 100%;
    height: 100%;
}

.circle-wrapper {
    margin: 0 auto;
    width: 225px;
    height: 225px;
    border-radius: 50%;
    position: relative;
}

.inside-circle {
    width: 180px;
    height: 180px;
    border-radius: 50%;
    text-align: center;
    margin-top: 22.5px;
    margin-left: 22.5px;
    position: absolute;
    font-weight: 700;
    font-size: 2em;
    overflow: hidden;
}
.inside-circle-numbers div {
    flex: 0 0 180px;
}

.circle-box-margin-top {
    margin-top: -120px;
}

.circle-progress-wrapper {
    position: absolute;
    width: 225px;
    height: 225px;
    transform: rotate(-90deg);
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
}

.circle-progress {
    stroke-dasharray: 424.12; // 2 * PI * 67.5
    stroke-dashoffset: 424.12; // 2 * PI * 67.5
    stroke-width: 15px;
    fill: transparent;
    stroke-linecap: round;
}

/* Circle 1: Progress & Counter */
@keyframes circle-progress-animation-1 {
    from {
        stroke-dashoffset: 424.12; // 2 * PI * 67.5
    }
    to {
        stroke-dashoffset: 0;
    }
}
@keyframes circle-number-animation-1 {
    0% {
        margin-left: 0;
    }
    100% {
        margin-left: -9000px; // 180 * 50
    }
}
[data-aos="circle-progress-animate-1"] {
    &.aos-animate {
        circle {
            animation: circle-progress-animation-1 2s linear forwards;
            -webkit-animation: circle-progress-animation-1 2s linear forwards;
        }
        .inside-circle-numbers-animate-1 {
            animation: circle-number-animation-1 2s linear forwards;
            animation-timing-function: steps(50);
            -webkit-animation: circle-number-animation-1 2s linear forwards;
            -webkit-animation-timing-function: steps(50);
        }
    }
}

/* Circle 2: Progress & Counter */
@keyframes circle-progress-animation-2 {
    from {
        stroke-dashoffset: 424.12; // 2 * PI * 67.5
    }
    to {
        stroke-dashoffset: 415.63; // 2 * PI * 67.5 * (100 - 2) / 100
    }
}
@keyframes circle-number-animation-2 {
    0% {
        margin-left: 0;
    }
    100% {
        margin-left: -360px; // 180 * 2
    }
}
[data-aos="circle-progress-animate-2"] {
    &.aos-animate {
        circle {
            animation: circle-progress-animation-2 .07s linear forwards;
            -webkit-animation: circle-progress-animation-2 .07s linear forwards;
        }
        .inside-circle-numbers-animate-2 {
            animation: circle-number-animation-2 .07s linear forwards;
            animation-timing-function: steps(2);
            -webkit-animation: circle-number-animation-2 .07s linear forwards;
            -webkit-animation-timing-function: steps(2);
        }
    }
}

/* Circle 3: Progress & Counter */
/* empty because value is zero */

.box-margin-top {
    margin-top: 140px;
}
.box-margin-top-mobile {
    margin-top: 120px;
}

.circle-1 {
    transform: rotate(57.6deg);
}

.circle-2 {
    transform: rotate(127.8deg);
}

.circle-3 {
    transform: rotate(115.2deg);
}

.circle-4 {
    transform: rotate(30.6deg);
}

.min-text-height {
    min-height: 7.5em;
}
.min-text-height-mobile {
    min-height: 6.5em;
}

.pos-absolute {
    position: absolute;
}
.pos-absolute-left-zero {
    left: 0;
}
.pos-absolute-bottom-zero {
    bottom: 0;
}

.is-hero-top {
    position: fixed;
    width: 100%;
    z-index: -1;
}

.is-horizontal-center {
    position: relative;
    left: 50%;
    transform: translate(-50%,0);
}

.line-height-0 {
    line-height:0;
}

.foreground {
    position: relative;
    z-index: 1;
}

.is-vertical-center {
    display: flex;
    align-items: center;
}
.add-horizontal-center {
    justify-content: center;
}

.pointer-hand {
    cursor: pointer;
}
.dropdown-menu, .background {
    cursor: default !important;
}

.modal {
    flex-direction: unset !important;
}
.modal-headline-center {
    display: flex;
    justify-content: center;
}

.transition-up {
    transition: all .5s;
    -o-transition: all .5s;
    -moz-transition: all .5s;
    -webkit-transition: all .5s;
}
.move-up-on-hover:hover {
    transform: translateY(-5px);
}


.message-border-color-2 {
    border-color: #556cfa !important;
}
.message-border-color-3 {
    border-color: #009e86 !important;
}
.message-border-color-4 {
    border-color: #e59b30 !important;
}
.message-border-color-9 {
    border-color: #00917c !important;
}

.transition-action-button-page-leave-active,
.transition-action-button-page-enter-active {
    transition: opacity .3s;
}
.transition-action-button-page-leave-to,
.transition-action-button-page-enter {
    opacity: 0;
}

.message-body a {
    text-decoration: none !important;
}

.display-inline-block {
    display: inline-block;
}

.display-none {
    display: none !important;
}

.border-radius-4 {
    border-radius: 4px;
}

.icons-right {
    justify-content: flex-end;
}
.icons-left {
    justify-content: flex-start;
}

.text-primary {
    color: #00917c;
}
.text-color-1 {
    color: #688457;
}
.text-color-2 {
    color: #e7bf6a;
}
.text-color-3 {
    color: #7b7b7b;
}
.text-color-4 {
    color: #8b6d9b;
}
.text-color-5 {
    color: #f86f91;
}

.overflow-hidden {
    overflow: hidden;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
}

.slick-dots li button::before {
    font-size: 10px !important;
}

.carousel-margin {
    padding-left: 1px;
    padding-right: 1px;
}
.carousel-margin-bottom {
    margin-bottom: 1em;
}
.carousel-margin-bottom-2 {
    margin-bottom: 2em;
}

div {
    outline : 0;
}

.message-no-border {
    border-style: unset !important;
    border-width: unset !important;
    border-color: unset !important;
}

.pagination-button {
    font-weight: bold;
    border: transparent;
}
.margin-right-pagination {
    margin-right: .25em;
}
.margin-left-pagination {
    margin-left: .25em;
}

address {
    font-style: normal !important;
}

.slick-dots li button::before {
    opacity: .25 !important;
}
.slick-dots li.slick-active button::before {
    opacity: 1 !important;
}

.button:focus {
    box-shadow: none !important;
}

.title-img {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
}

.navbar-height {
    height: 3.25rem;
}

.code-block-custom {
    font-family: Consolas, Monaco, Lucida Console, Liberation Mono, DejaVu Sans Mono, Bitstream Vera Sans Mono, Courier New, serif;
    color: #a7b5c4;
    background-color: #1f2330 !important;
    border: 2px solid #1f2330;
    background-repeat: no-repeat;
    background-position: 1.25rem 1.25rem;
    background-image: url('data:image/svg+xml;utf8, <svg xmlns="http://www.w3.org/2000/svg" width="54" height="14" viewBox="0 0 54 14"><g fill="none" transform="translate(1 1)"><circle cx="6" cy="6" r="6" fill="%23ff5f57" stroke="%23da372f"/><circle cx="26" cy="6" r="6" fill="%23febc2e" stroke="%23d99505"/><circle cx="46" cy="6" r="6" fill="%2328c840" stroke="%2300a117"/></g></svg>');
}
.code-block-custom-padding {
    padding-top: 2rem !important;
}

.code-box-border {
    border: 2px solid #00917c !important;
}

.auth-margin-error {
    margin-top: 1em;
}

.custom-checkbox-wrapper {
    display: inline-block;
    position: relative;
}

.align-right {
    justify-content: flex-end;
}

.margin-navbar {
    margin-top: 3.75rem;
}

.right-125rem {
    right: 1.25rem;
}

.margin-top-unset {
    margin-top: unset !important;
}
.margin-bottom-unset {
    margin-bottom: unset;
}

.button-margin-top {
    margin-top: 1em;
}

.notification-custom {
    position: fixed;
    top: 0;
    z-index: 29;
    margin-left: .5rem;
    margin-right: .5rem;
}
